import { Link } from "gatsby"
import React from "react"
import Contact from "../components/Contact"
import WrapperRight from "../components/WrapperRight"
import { useStaticQuery, graphql } from "gatsby"

const Forum = () => {
  const FORUM_POSTS = useStaticQuery(graphql`
    {
      work: allStrapiPosts(
        filter: { post_category: { postCategory: { eq: "お仕事" } } }
        sort: { fields: created_at, order: DESC }
      ) {
        edges {
          node {
            id
            title
            description
            nickname
            email
            created_at(formatString: "MM/DD/YYYY")
            post_category {
              postCategory
            }
          }
        }
      }
      life: allStrapiPosts(
        filter: { post_category: { postCategory: { eq: "生活" } } }
        sort: { fields: created_at, order: DESC }
      ) {
        edges {
          node {
            id
            title
            description
            nickname
            email
            created_at(formatString: "MM/DD/YYYY")
            post_category {
              postCategory
            }
          }
        }
      }
      residence: allStrapiPosts(
        filter: { post_category: { postCategory: { eq: "住居" } } }
        sort: { fields: created_at, order: DESC }
      ) {
        edges {
          node {
            id
            title
            description
            nickname
            email
            created_at(formatString: "MM/DD/YYYY")
            post_category {
              postCategory
            }
          }
        }
      }
      sell: allStrapiPosts(
        filter: { post_category: { postCategory: { eq: "売ります" } } }
        sort: { fields: created_at, order: DESC }
      ) {
        edges {
          node {
            id
            title
            description
            nickname
            email
            created_at(formatString: "MM/DD/YYYY")
            post_category {
              postCategory
            }
          }
        }
      }
      getit: allStrapiPosts(
        filter: { post_category: { postCategory: { eq: "買います" } } }
        sort: { fields: created_at, order: DESC }
      ) {
        edges {
          node {
            id
            title
            description
            nickname
            email
            created_at(formatString: "MM/DD/YYYY")
            post_category {
              postCategory
            }
          }
        }
      }
      info: allStrapiPosts(
        filter: { post_category: { postCategory: { eq: "イベント情報" } } }
        sort: { fields: created_at, order: DESC }
      ) {
        edges {
          node {
            id
            title
            description
            nickname
            email
            created_at(formatString: "MM/DD/YYYY")
            post_category {
              postCategory
            }
          }
        }
      }
    }
  `)

  const dataWork = FORUM_POSTS.work.edges
  const dataLife = FORUM_POSTS.life.edges
  const dataResidence = FORUM_POSTS.residence.edges
  const dataSell = FORUM_POSTS.sell.edges
  const dataGetit = FORUM_POSTS.getit.edges
  const dataInfo = FORUM_POSTS.info.edges

  return (
    <>
      <section className="container forum-content">
        <div className="row">
          <div className="col-lg-9 col-lg-9">
            <div className="forum-header">
              <h1>メキシコ生活お役立ち掲示板</h1>
              <Link to="/forum-post">今すぐ質問！</Link>
            </div>
            <section className="category">
              <div>
                <p className="category_title">お仕事</p>
                {dataWork.length !== 0 ? (
                  <ul className="category_list">
                    {dataWork.map((item, i) => (
                      <li key={i}>
                        <p>{item.node.created_at}</p>
                        <Link to={`/forum/${item.node.id}`}>
                          {item.node.title.length <= 10
                            ? item.node.title.slice(0, 10)
                            : item.node.title.slice(0, 9) + "..."}
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="category_nodata">
                    Nothing about this category.
                  </p>
                )}
              </div>
              <div>
                <p className="category_title">生活</p>
                {dataLife.length !== 0 ? (
                  <ul className="category_list">
                    {dataLife.map((item, i) => (
                      <li key={i}>
                        <p>{item.node.created_at}</p>
                        <Link to={`/forum/${item.node.id}`}>
                          {item.node.title.length <= 10
                            ? item.node.title.slice(0, 10)
                            : item.node.title.slice(0, 9) + "..."}
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="category_nodata">
                    Nothing about this category.
                  </p>
                )}
              </div>
              <div>
                <p className="category_title">住居</p>
                {dataResidence.length !== 0 ? (
                  <ul className="category_list">
                    {dataResidence.map((item, i) => (
                      <li key={i}>
                        <p>{item.node.created_at}</p>
                        <Link to={`/forum/${item.node.id}`}>
                          {item.node.title.length <= 10
                            ? item.node.title.slice(0, 10)
                            : item.node.title.slice(0, 9) + "..."}
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="category_nodata">
                    Nothing about this category.
                  </p>
                )}
              </div>
              <div>
                <p className="category_title">売ります</p>
                {dataSell.length !== 0 ? (
                  <ul className="category_list">
                    {dataSell.map((item, i) => (
                      <li key={i}>
                        <p>{item.node.created_at}</p>
                        <Link to={`/forum/${item.node.id}`}>
                          {item.node.title.length <= 10
                            ? item.node.title.slice(0, 10)
                            : item.node.title.slice(0, 9) + "..."}
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="category_nodata">
                    Nothing about this category.
                  </p>
                )}
              </div>
              <div>
                <p className="category_title">買います</p>
                {dataGetit.length !== 0 ? (
                  <ul className="category_list">
                    {dataGetit.map((item, i) => (
                      <li key={i}>
                        <p>{item.node.created_at}</p>
                        <Link to={`/forum/${item.node.id}`}>
                          {item.node.title.length <= 10
                            ? item.node.title.slice(0, 10)
                            : item.node.title.slice(0, 9) + "..."}
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="category_nodata">
                    Nothing about this category.
                  </p>
                )}
              </div>
              <div>
                <p className="category_title">イベント情報</p>
                {dataInfo.length !== 0 ? (
                  <ul className="category_list">
                    {dataInfo.map((item, i) => (
                      <li key={i}>
                        <p>{item.node.created_at}</p>
                        <Link to={`/forum/${item.node.id}`}>
                          {item.node.title.length <= 10
                            ? item.node.title.slice(0, 10)
                            : item.node.title.slice(0, 9) + "..."}
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="category_nodata">
                    Nothing about this category.
                  </p>
                )}
              </div>
            </section>
            <Contact />
          </div>
          <WrapperRight />
        </div>
      </section>
    </>
  )
}
export default Forum
